import axios from "axios"
export const classDetails = () => {
    let URL = `${process.env.REACT_APP_API_URL}/userClass/class-details`
    const token = localStorage.getItem("access_admin_token")
    const headers = {
        "content-Type": "application/json",
        "token": token
    }
    return axios({
        url: URL,
        method: "GET",
        headers: headers
    })
        .then(response => {
            // console.log("session is ",response)
            return response.data
        })
}

export const blockUser = (args) => {
    let URL = `${process.env.REACT_APP_API_URL}/user/block-user`
    const token = localStorage.getItem("access_admin_token")
    const headers = {
        "content-Type": "application/json",
        "token": token
    }
    return axios({
        url: URL,
        method: "PUT",
        headers: headers,
        data: JSON.stringify(args)
    })
        .then(response => {
            // console.log("session is ",response)
            return response.data
        })
}

export const deleteUser = (args) => {
    let URL = `${process.env.REACT_APP_API_URL}/user/delete-user`
    const token = localStorage.getItem("access_admin_token")
    const headers = {
        "content-Type": "application/json",
        "token": token
    }
    return axios({
        url: URL,
        method: "DELETE",
        headers: headers,
        data: JSON.stringify(args)
    })
        .then(response => {
            // console.log("session is ",response)
            return response.data
        })
}

export const getFieldsForMapping = (args) => {
    let URL = `${process.env.REACT_APP_API_URL}/user/show-field`
    const token = localStorage.getItem("access_admin_token")
    const headers = {
        "content-Type": "application/json",
        "token": token
    }
    return axios({
        url: URL,
        method: "GET",
        headers: headers,
        params: args
    })
        .then(response => {
            // console.log("session is ",response)
            return response.data
        })
}

export const uploadFileToS3 = (payload) => {

    let URL = `${process.env.REACT_APP_API_URL}/upload`

    let formData = new FormData()
    formData.append('file', payload)

    return axios.post(URL, formData)
        .then(response => {
            return response.data
        })
}


export const importUsers = (args, type) => {
    let URL = `${process.env.REACT_APP_API_URL}/user/import-${type}`
    const token = localStorage.getItem("access_admin_token")
    const headers = {
        "content-Type": "application/json",
        "token": token
    }
    return axios({
        url: URL,
        method: "POST",
        headers: headers,
        data: JSON.stringify(args)
    }).then(response => {
        // console.log("session is ",response)
        return response.data
    })
}
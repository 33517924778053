import React from "react"
import { Navbar } from "reactstrap"
import { connect } from "react-redux"
import classnames from "classnames"
import { useAuth0 } from "../../../authServices/auth0/auth0Service"
import {
  logoutWithJWT,
  logoutWithFirebase
} from "../../../redux/actions/auth/loginActions"
import NavbarUser from "./NavbarUser"
import userImg from "../../../assets/img/portrait/small/avatar-s-11.jpg"
import { fecthAdminProfilePage } from "../../../redux/actions/adminManagement/adminManagement.action";
import {useEffect} from 'react';
import { useDispatch} from 'react-redux';
import {useLocation } from 'react-router-dom';


// const UserName = props => {
//   let username = ""
//   if (props.userdata !==undefined){
//     if (
//           props.userdata.loggedInWith !== undefined &&
//           props.userdata.loggedInWith === "jwt"
//         ) {
//           username = props.userdata.loggedInUser.user.first_name
//         }

//   }else{
//     username = "Hello Bran !"
//   }
 
  // if (props.userdata !== undefined) {
  //   username = props.userdata.name
  // } else if (props.user.login.values !== undefined) {
  //   username = props.user.login.values.name
  //   if (
  //     props.user.login.values.loggedInWith !== undefined &&
  //     props.user.login.values.loggedInWith === "jwt"
  //   ) {
  //     username = props.user.login.values.loggedInUser.user.first_name
  //   }
  // } else {
  //   username = "Hello Bran !"
  // }
//   console.log(username,"seee ")
//   return username
// }

const titleCase =(str)=> {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
  }
  return splitStr.join(' '); 
}


const ThemeNavbar = props => {
  // const { user } = useAuth0()
   const dispatch = useDispatch()
  useEffect(() => {
    console.log("into useefefct")
    dispatch(props.fecthAdminProfilePage())
    },[])

const location = useLocation()
const navbarHeading=location.pathname.replace("-"," ").substring(1)
console.log(navbarHeading,"navbarHeading")
const navbarTitle = titleCase(navbarHeading);

console.log(navbarTitle,"navBar=========")


  
  const{user} = props
  console.log("user page",user)
  const colorsArr = [ "primary", "danger", "success", "info", "warning", "dark"]
  const navbarTypes = ["floating" , "static" , "sticky" , "hidden"]
  return (
    <React.Fragment>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className={classnames(
          "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
          {
            "navbar-light": props.navbarColor === "default" || !colorsArr.includes(props.navbarColor),
            "navbar-dark": colorsArr.includes(props.navbarColor),
            "bg-primary":
              props.navbarColor === "primary" && props.navbarType !== "static",
            "bg-danger":
              props.navbarColor === "danger" && props.navbarType !== "static",
            "bg-success":
              props.navbarColor === "success" && props.navbarType !== "static",
            "bg-info":
              props.navbarColor === "info" && props.navbarType !== "static",
            "bg-warning":
              props.navbarColor === "warning" && props.navbarType !== "static",
            "bg-dark":
              props.navbarColor === "dark" && props.navbarType !== "static",
            "d-none": props.navbarType === "hidden" && !props.horizontal,
            "floating-nav":
              (props.navbarType === "floating" && !props.horizontal) || (!navbarTypes.includes(props.navbarType) && !props.horizontal),
            "navbar-static-top":
              props.navbarType === "static" && !props.horizontal,
            "fixed-top": props.navbarType === "sticky" || props.horizontal,
            "scrolling": props.horizontal && props.scrolling

          }
        )}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex justify-content-between align-items-center"
              id="navbar-mobile"
            >
              <div className="bookmark-wrapper">
                <h5 className="mb-0 blue-text">{navbarTitle ? navbarTitle=="Dashboard"?"DASHBOARD":navbarTitle:"DASHBOARD"}</h5>
              </div>
              <NavbarUser
                handleAppOverlay={props.handleAppOverlay}
                changeCurrentLang={props.changeCurrentLang}
                userName={               
                  props.admin_profile !== undefined && 
                  props.admin_profile.first_name &&
                  props.admin_profile.first_name?"Hello " + props.admin_profile.first_name:"Hello Bran"
               
              
              }
                userImg={ props.admin_profile !== undefined && 
                  props.admin_profile.profile_picture  &&
                  props.admin_profile.profile_picture  ? props.admin_profile.profile_picture :userImg
                 
                }
                loggedInWith={
                  props.user !== undefined && 
                  props.user.login !==undefined && 
                  props.user.login.values !== undefined
                    ? props.user.login.values.loggedInWith
                    : null
                }
                logoutWithJWT={props.logoutWithJWT}
                logoutWithFirebase={props.logoutWithFirebase}
              />
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    user: state.auth.login.values,
    admin_profile: state.adminManagement.profile
  }
}

const mapDispatchToProps = dispatch => {
  return {

    logoutWithJWT,
    logoutWithFirebase,
    useAuth0,
    fecthAdminProfilePage:()=>dispatch(fecthAdminProfilePage)
   
  }
};

export default connect(mapStateToProps,mapDispatchToProps)(ThemeNavbar)

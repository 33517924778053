import * as constants from "../../constants";

const initialState = {
  serverInformationList: null,
};

export const serverInformation = (state = initialState, action) => {
  switch (action.type) {
    case constants.SHOW_LIST_SERVER_INFORMATION: {
      return { ...state, serverInformationList: action.payload };
    }

    default: {
      return state;
    }
  }
};

import React from "react"
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge
} from "reactstrap"
import PerfectScrollbar from "react-perfect-scrollbar"
import * as Icon from "react-feather"
import { useAuth0 } from "../../../authServices/auth0/auth0Service"
import { history } from "../../../history"
import { connect } from 'react-redux'
import * as notificationActions from '../../../redux/actions/notification/notification.action'
import moment from "moment"

const handleNavigation = (e, path) => {
  e.preventDefault()
  history.push(path)
}

const UserDropdown = props => {
  const { logout, isAuthenticated } = useAuth0()

  return (
    <DropdownMenu right>
      <DropdownItem
        tag="a"
        href="/admin-profile"
        onClick={e => handleNavigation(e, "/admin-profile")}
      >
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">Profile</span>
      </DropdownItem>
      <DropdownItem divider />
      <DropdownItem
        tag="a"
        href="/login"
        onClick={e => {
          e.preventDefault()

          if (isAuthenticated) {
            props.clearAll()
            localStorage.setItem('access_admin_token', null);
            return (logout({
              returnTo: window.location.origin + process.env.REACT_APP_PUBLIC_PATH
            }))
          } else {
            const provider = props.loggedInWith

            if (provider !== null) {
              if (provider === "jwt") {

                return props.logoutWithJWT()
              }
              if (provider === "firebase") {
                return props.logoutWithFirebase()
              }
            } else {
              localStorage.setItem('access_admin_token', null);
              localStorage.setItem('user', null);
              props.clearAll()
              history.push("/login")

            }
          }

        }}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  )
}

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    langDropdown: false,
    suggestions: [],
    readAllNotification: true
  }

  /* handleNotifications = (e) => {
    this.props.bellNotificationAction()
    this.props.setNotificationAvailableStatus(false)
  }
 */
  componentDidMount(){
    this.props.bellNotificationAction()
    this.props.setNotificationAvailableStatus(false)
  }

  clickOnNotifications = (item) => {
    if(item.is_unread){
      this.props.markReadBellNotificationAction({ notification_id: item._id })
    }
    history.push({ pathname: `/${item.href}/${item._id}`/* notificationData: item  */})
    this.props.setNotificationAvailableStatus(false);

  }

  getNotificationTime = (date) => {
    const currentTime = moment()
    const days = currentTime.diff(date, 'days')
    const dayString = days ? `${days} days ` : ''
    const hours = currentTime.diff(date, 'hours')
    const hourString = days ? '' : hours ? `${hours} hours ` : ''
    const minutes = currentTime.diff(date, 'minutes')
    const minuteString = (days || hours) ? '' : minutes ? `${minutes} minutes ` : ''
    const seconds = currentTime.diff(date, 'seconds')
    const secondString = (days || hours || minutes) ? '' : `${seconds} seconds `
    return dayString + hourString + minuteString + secondString + 'ago'
  }

  markAllReadNotification = () => {
    this.props.markReadBellNotificationAction({ read_all: 'true' })
    this.setState({ readAllNotification: false });

  }

  render() {
    console.log("render navbarUser", this.props)
    const { bellNotifications, newNotificationAvaliable ,Notifications} = this.props

    console.log('bellNotifications',bellNotifications);

    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item"
        >
          <DropdownToggle tag="a" className="nav-link nav-link-label"/*  onClick={(e) => this.handleNotifications(e)} */>
            <Icon.Bell size={21} />
            { newNotificationAvaliable ? (
              <Badge pill color="primary" className="badge-up">
                {" "}
                *{" "}
              </Badge>
            ) : <Badge pill color="primary" className="badge-up">
              {Notifications.total_unread !==0 && this.state.readAllNotification && Notifications.total_unread ?Notifications.total_unread : ""}
            </Badge>}
          </DropdownToggle>
          <DropdownMenu tag="ul" right className="dropdown-menu-media">
            <li className="dropdown-menu-header">
              <div className="dropdown-header mt-0">
                <h3 className="text-white">{bellNotifications?.length} New</h3>
                <span className="notification-title">App Notifications</span>
              </div>
            </li>
            <PerfectScrollbar
              className="media-list overflow-hidden position-relative"
              options={{
                wheelPropagation: false
              }}
            >
              {
                bellNotifications.map(item => {
                  return <div onClick={() => this.clickOnNotifications(item)} /* href={`${item.href? item.href : '#'}`} */>
                    <div className="d-flex justify-content-between" style={item?.is_unread?null:{background:'#f1f5f8'}}>
                      <Media className="d-flex align-items-start">
                        <Media left href="#">
                          <Icon.PlusSquare
                            className="font-medium-5 primary"
                            size={21}
                          />
                        </Media>
                        <Media body>
                          <Media heading className="primary media-heading" tag="h6">
                            {item.title}
                          </Media>
                          <p className="notification-text">
                            {item.description}
                          </p>
                        </Media>
                        <small>
                          <time
                            className="media-meta"
                            dateTime="2015-06-11T18:29:20+08:00"
                          >
                            {this.getNotificationTime(item.createdAt)}
                          </time>
                        </small>
                      </Media>
                    </div>
                  </div>
                })
              }

              {/* <div className="d-flex justify-content-between">
                <Media className="d-flex align-items-start">
                  <Media left href="#">
                    <Icon.DownloadCloud
                      className="font-medium-5 success"
                      size={21}
                    />
                  </Media>
                  <Media body>
                    <Media heading className="success media-heading" tag="h6">
                      99% Server load
                    </Media>
                    <p className="notification-text">
                      You got new order of goods?
                    </p>
                  </Media>
                  <small>
                    <time
                      className="media-meta"
                      dateTime="2015-06-11T18:29:20+08:00"
                    >
                      5 hours ago
                    </time>
                  </small>
                </Media>
              </div> */}

            </PerfectScrollbar>
            <li className="dropdown-menu-footer">
              <DropdownItem tag="a" className="p-1 text-center">
                <span className="align-middle" onClick={() => this.markAllReadNotification()}>Read all notifications</span>
              </DropdownItem>
            </li>
          </DropdownMenu>
        </UncontrolledDropdown>
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-500 mb-0">
                {this.props.userName}
              </span>
            </div>
            <span data-tour="user">
              <img
                src={this.props.userImg}
                className="round"
                height="40"
                width="40"
                alt="avatar"
              />
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    )
  }
}
const mapDispatchToProps = dispatch => {
  return {
    clearAll: () => {
      dispatch({ type: "DESTROY_SESSION" })
    },
    bellNotificationAction: () => {
      dispatch(notificationActions.bellNotificationAction())

    },
    setNotificationAvailableStatus: (payload) => {
      dispatch(notificationActions.setNotificationAvailableStatus(payload))
    },
    markReadBellNotificationAction: (payload) => {
      dispatch(notificationActions.markReadBellNotificationAction(payload))
    },
  }
};
const mapStateToProps = state => {
  return {
    user: state.auth.login.values.loggedInUser.user,
    bellNotifications: state.notifications.bellNotification,
    Notifications: state.notifications,
    newNotificationAvaliable: state.notifications.newNotificationAvaliable
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavbarUser)

import * as firebase from "firebase/app";
import { history } from "../../../history";
import "firebase/auth";
import "firebase/database";
import axios from "axios";
import { config } from "../../../authServices/firebase/firebaseConfig";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  loginInfoFetch,
  forgotPassword,
  resetPassword,
  getOtpForLogin,
  loginWithOtpService
} from "../../services/userServices/userServices";
import * as actions from "../../constants/loginConstants/loginConstants";
import * as SecureLS from "secure-ls";
toast.configure();
const SecureLocalstorage = new SecureLS();
// Init firebase if not already initialized
if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

let firebaseAuth = firebase.auth();

// const initAuth0 = new auth0.WebAuth(configAuth)

export const submitLoginWithFireBase = (email, password, remember) => {
  return (dispatch) => {
    let userEmail = null,
      loggedIn = false;
    firebaseAuth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        firebaseAuth.onAuthStateChanged((user) => {
          result.user.updateProfile({
            displayName: "Admin",
          });
          let name = result.user.displayName;
          if (user) {
            userEmail = user.email;
            loggedIn = true;
            dispatch({
              type: "LOGIN_WITH_EMAIL",
              payload: {
                email: userEmail,
                name,
                isSignedIn: loggedIn,
                loggedInWith: "firebase",
              },
            });
          }
          if (user && remember) {
            firebase
              .auth()
              .setPersistence(firebase.auth.Auth.Persistence.SESSION)
              .then(() => {
                dispatch({
                  type: "LOGIN_WITH_EMAIL",
                  payload: {
                    email: userEmail,
                    name,
                    isSignedIn: loggedIn,
                    remember: true,
                    loggedInWith: "firebase",
                  },
                });
              });
          }
          history.push("/");
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const loginWithFB = () => {
  return (dispatch) => {
    let provider = new firebase.auth.FacebookAuthProvider();
    provider.setCustomParameters({
      display: "popup",
    });
    firebaseAuth
      .signInWithPopup(provider)
      .then((result) => {
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        let token = result.credential.accessToken,
          // The signed-in user info.
          user = result.user.email;
        dispatch({
          type: "LOGIN_WITH_FB",
          payload: {
            user,
            token,
            loggedInWith: "firebase",
          },
        });
        if (user) history.push("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const loginWithTwitter = () => {
  return (dispatch) => {
    let provider = new firebase.auth.TwitterAuthProvider();
    firebaseAuth
      .signInWithPopup(provider)
      .then(function (result) {
        let token = result.credential.accessToken,
          user = result.user.email,
          name = result.user.displayName,
          photoUrl = result.user.photoURL;
        dispatch({
          type: "LOGIN_WITH_TWITTER",
          payload: {
            user,
            name,
            photoUrl,
            token,
            loggedInWith: "firebase",
          },
        });
        history.push("/");
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const loginWithGoogle = () => {
  return (dispatch) => {
    let provider = new firebase.auth.GoogleAuthProvider();
    firebaseAuth
      .signInWithPopup(provider)
      .then(function (result) {
        let token = result.credential.accessToken,
          user = result.user.email,
          name = result.user.displayName,
          photoUrl = result.user.photoURL;
        dispatch({
          type: "LOGIN_WITH_GOOGLE",
          payload: {
            email: user,
            name: name,
            photoUrl,
            token,
            loggedInWith: "firebase",
          },
        });
        history.push("/");
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const loginWithGithub = () => {
  return (dispatch) => {
    let provider = new firebase.auth.GithubAuthProvider();
    firebaseAuth
      .signInWithPopup(provider)
      .then(function (result) {
        let token = result.credential.accessToken,
          user = result.user.email,
          name = result.additionalUserInfo.username,
          photoUrl = result.user.photoURL;

        dispatch({
          type: "LOGIN_WITH_GITHUB",
          payload: {
            user,
            name,
            photoUrl,
            token,
            loggedInWith: "firebase",
          },
        });
        history.push("/");
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const loginWithJWT = (payload) => {
  return (dispatch) => {
    return loginInfoFetch(payload)
      .then((response) => {
        var loggedInUser;
        if (response.data) {
          loggedInUser = response.data;
          console.log("logged in with jwt", response);
          if (payload.remember && payload.email !== "") {
            localStorage.username = payload.email;
            // localStorage.password = password
            SecureLocalstorage.set("password", payload.password);
            localStorage.remember = payload.remember;
          } else {
            localStorage.removeItem("username");
            localStorage.removeItem("remember");
            SecureLocalstorage.remove("password");
          }
          dispatch({
            type: actions.LOGIN_WITH_JWT,
            payload: { loggedInUser, loggedInWith: "jwt" },
          });
          localStorage.setItem("access_admin_token", response.data.token);
          localStorage.setItem("user", JSON.stringify(response.data.user));
          toast.success(response.message);
          let userRole = response.data.user.role;
          if (userRole === 0) {
            history.push("/attendence-report");
          } else {
            history.push("/");
          }

        }
      })
      .catch((error) => {
        console.log(error, "error in login");
        toast.error(
          error.response && error.response.data && error.response.data.message
        );
        localStorage.setItem("access_admin_token", null);
        dispatch({
          type: actions.LOGIN_JWT_FAILED,
        });
      });
  };
};
export const setUserToStore = (payload) => {
  console.log("update token", payload);
  var loggedInUser = payload;
  console.log("set user to store on reload", loggedInUser);
  return (dispatch) => {
    dispatch({
      type: actions.UPDATE_TOKEN,
      payload: { loggedInUser, loggedInWith: "jwt" },
    });
  };
};

export const logoutWithJWT = () => {
  return (dispatch) => {
    dispatch({ type: "LOGOUT_WITH_JWT", payload: {} });
    history.push("/pages/login");
    localStorage.setItem("access_admin_token", null);
  };
};

export const logoutWithFirebase = (user) => {
  return (dispatch) => {
    dispatch({ type: "LOGOUT_WITH_FIREBASE", payload: {} });
    history.push("/pages/login");
  };
};

export const changeRole = (role) => {
  return (dispatch) => dispatch({ type: "CHANGE_ROLE", userRole: role });
};

export const forgotPasswordAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actions.USER_FORGOT_PASSWORD_REQUEST,
    });

    return forgotPassword(payload)
      .then((response) => {
        dispatch({
          type: actions.USER_FORGOT_PASSWORD_SUCCESS,
          payload: response.data,
        });
        toast.success(response.message);

        console.log("in then  bloc of forgot");
      })
      .catch((err) => {
        console.log("in cathc  bloc of forgot");
        toast.error(err.response && err.response.data.message);
        dispatch({
          type: actions.USER_FORGOT_PASSWORD_FAIL,
        });
        history.push("/login");
      });
  };
};
export const resetPasswordAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actions.USER_RESET_PASSWORD_REQUEST,
    });
    return resetPassword(payload)
      .then((response) => {
        dispatch({
          type: actions.USER_RESET_PASSWORD_SUCCESS,
          payload: response.data,
        });
        localStorage.setItem("access_token", response.data.access_token);
        toast.success("Password Reset Successfully");
        history.push("/login");
        localStorage.setItem("otp", null);
      })
      .catch((error) => {
        toast.error("OTP not verified");
        dispatch({
          type: actions.USER_RESET_PASSWORD_FAIL,
        });
        // history.push("/dashboard")
      });
  };
};


export const getOtpForLoginAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actions.GET_OTP_FOR_LOGIN_REQUEST,
    });

    return getOtpForLogin(payload)
      .then((response) => {
        dispatch({
          type: actions.GET_OTP_FOR_LOGIN_SUCCESS,
          payload: response.data,
        });
        toast.success(response.message);
      })
      .catch((err) => {
        toast.error(err.response && err.response.data.message);
        dispatch({
          type: actions.GET_OTP_FOR_LOGIN_FAILED,
        });
      });
  }
};

export const loginWithOtpAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actions.LOGIN_WITH_OTP_REQUEST,
    });

    return loginWithOtpService(payload)
      .then((response) => {
        dispatch({
          type: actions.LOGIN_WITH_OTP_SUCCESS,
          payload: { loggedInUser: response.data, loggedInWith: "jwt" },
        });
        localStorage.setItem("access_admin_token", response.data.token);
        toast.success(response.message);
        history.push("/")
      })
      .catch((err) => {
        toast.error(err.response && err.response.data.message);
        dispatch({
          type: actions.LOGIN_WITH_OTP_FAILED,
        });
      });
  }
};
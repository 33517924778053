export const SAVE_CLASS_LISTING = 'SAVE_CLASS_LISTING'
export const SAVE_CLASS_SEARCH_LISTING = 'SAVE_CLASS_SEARCH_LISTING'
export const SAVE_CLASS_SEARCH_STRING = 'SAVE_CLASS_SEARCH_STRING'
export const SET_LISTING_LIMIT = 'SET_LISTING_LIMIT'
export const SAVE_CLASS_DETAILS = 'SAVE_CLASS_DETAILS'
export const SET_CLASS_PROFILE_SIDEBAR_VISIBILITY = 'SET_CLASS_PROFILE_SIDEBAR_VISIBILITY'
export const SET_ADD_CLASS_SIDEBAR_VISIBILITY = 'SET_ADD_CLASS_SIDEBAR_VISIBILITY'
export const SET_ACTIVE_CLASS_PROFILE = 'SET_ACTIVE_CLASS_PROFILE'
export const CLEAR_CLASS_LISTING = 'CLEAR_CLASS_LISTING'
export const SET_CLASS_CHECKED_ITEMS = 'SET_CLASS_CHECKED_ITEMS'
export const SET_CLASS_FILTER_SIDEBAR_VISIBILITY = 'SET_CLASS_FILTER_SIDEBAR_VISIBILITY'
export const SET_CLASS_FILTERS = 'SET_CLASS_FILTERS'
export const SET_SINGLE_CLASS_LIST = 'SET_SINGLE_CLASS_LIST'
export const SET_SINGLE_CLASS_VISIBILITY = 'SET_SINGLE_CLASS_VISIBILITY'


import * as constants from "../../constants";


const initialState = {
  currentAttendance: "student",
  activePage: 1,
  searchListing: [],
  searchStudentListing: [],
  searchString: "",
  limit: 10,
  total: 0,
  classDetails: {},
  activeProfile: {},
  activeStudentProfile: {},
  profileSidebarVisibility: false,
  attendaceChart: {},
  attendaceStudentChart: {},
  checkedItems: new Map(),
  checkedStudentItems: new Map(),
  filterSidebarVisibility: false,
  filters: {},
  filterStudent: {},
  filteredListing: {},
  filteredStudentListing: {},
};

export const attendanceManagement = (state = initialState, action) => {
  switch (action.type) {
    case constants.SAVE_SEARCH_LISTING: {
      const oldListing = action.payload;
      if (state.currentAttendance == "teacher") {
        return {
          ...state,
          searchListing: oldListing,
        };
      } else
        return {
          ...state,
          searchStudentListing: oldListing,
        };
    }
    case constants.SAVE_SEARCH_STRING: {
      return { ...state, searchString: action.payload };
    }
    case constants.SET_LISTING_LIMIT: {
      return { ...state, limit: action.payload };
    }
    case constants.SAVE_CLASS_DETAILS:
      return {
        ...state,
        classDetails: action.payload,
      };
    case constants.SET_ACTIVE_PROFILE:
      if (state.currentAttendance == "teacher") {
        return {
          ...state,
          activeProfile: action.payload,
        };
      } else
        return {
          ...state,
          activeStudentProfile: action.payload,
        };
    case constants.SET_PROFILE_SIDEBAR_VISIBILITY:
      return {
        ...state,
        profileSidebarVisibility: action.payload,
      };

    case constants.SET_FILTER_SIDEBAR_VISIBILITY:
      return {
        ...state,
        filterSidebarVisibility: action.payload,
      };
    case constants.SET_CHECKED_ITEMS:
      if (state.currentAttendance == "teacher") {
        return {
          ...state,
          checkedItems: action.payload,
        };
      } else
        return {
          ...state,
          checkedStudentItems: action.payload,
        };

    case constants.SET_FILTERS:
      if (state.currentAttendance == "teacher") {
        return {
          ...state,
          filters: action.payload,
        };
      } else
        return {
          ...state,
          filterStudent: action.payload,
        };
    case constants.SWITCH_ATTENDANCE_TABLE: {
      return {
        ...state,
        currentAttendance: action.payload,
      };
    }
    case constants.SAVE_ATTENDANCE_CHART: {
      if (state.currentAttendance == "teacher") {
        return {
          ...state,
          attendaceChart: action.payload,
        };
      } else
        return {
          ...state,
          attendaceStudentChart: action.payload,
        };
    }
    case constants.CLEAR_LISTING:
      if (state.currentAttendance == "student") {
        return {
          ...state,
          filteredListing: {},
          activePage: 1,
          searchListing: [],
          checkedItems: new Map(),
          profileSidebarVisibility: false,
          attendaceChart: {},
          filterSidebarVisibility: false,
          filters: {},
          activeProfile: {},
          total: 0
        };
      } else {
        return {
          ...state,
          filteredStudentListing: {},
          activePage: 1,
          searchListing: [],
          profileSidebarVisibility: false,
          filterSidebarVisibility: false,
          checkedStudentItems: new Map(),
          attendaceStudentChart: {},
          filterStudent: {},
          activeStudentProfile: {},
          searchStudentListing: [],
          total: 0
        };
      }
    case constants.SAVE_FILTERED_LISTING: {
      const oldListing = { ...state.filteredListing };
      oldListing[action.payload.page] = action.payload;
      const activePage = action.payload.page;
      const total = action.payload.total_data;
      if (state.currentAttendance == "teacher") {
        return {
          ...state,
          filteredListing: oldListing,
          activePage,
          total: total,
        };
      } else {
        return {
          ...state,
          filteredStudentListing: oldListing,
          activePage,
          total: total,
        };
      }
    }
    default: {
      return state;
    }
  }
};

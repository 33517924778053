import React from "react"
import * as Icon from "react-feather"


export const navigationConfigAdmin =  [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/dashboard",
    filterBase: "/dashboard"
  },
  {
    id: "teacher_management",
    title: "Teacher Management",
    type: "item",
    icon: <Icon.Book size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/teacher-management"
  },

  {
    id: "student_management",
    title: "Student Management",
    type: "item",
    icon: <Icon.Users size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/student-management"
  },
  {
    id: "class_management",
    title: "Class Management",
    type: "item",
    icon: <Icon.Monitor size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/class-management"
  },
  {
    id: "setup",
    title: "Setup",
    type: "item",
    icon: <Icon.Sliders size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/setup"
  },

] ;
export let navigationTeacher = [
  /* {
    id: "attendence_management",
    title: "Attendence Management",
    type: "item",
    icon: <Icon.Monitor size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/attendence-management"
  } ,*/
  {
    id: "attendence_report",
    title: "Attendence Report",
    type: "item",
    icon: <Icon.Clipboard size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/attendence-report"
  },
]

//export default navigationConfigAdmin

import * as constants from '../../constants'


const initialState = {
  
  classListing: {},
  searchClassListing: [],
  searchString: '',
  activeClassProfile: {},
  classProfileSidebarVisibility: false,
  addClassSidebarVisibility: false,
  classCheckedItems: new Map(),
  viewSingleClassVisibility:false,
  singleClassList :{},
  className:"",
  classTotalStudents:"",
  totalSection:"",
  checkedItems: new Map(),
  // classFilterSidebarVisibility : false,
  // classFilters : {},
 
}

export const classManagement = (state = initialState, action) => {
  switch (action.type) {
    case constants.SAVE_CLASS_LISTING: {
      const oldListing = {...action.payload}
      return { ...state, classListing: oldListing }
    }
    case constants.SAVE_CLASS_SEARCH_LISTING: {
      const oldListing = action.payload
      // oldListing[action.payload.page] = action.payload
      // const activePage = action.payload.page
      return { ...state, searchClassListing: oldListing }
    }
    case constants.SAVE_CLASS_SEARCH_STRING: {

      return { ...state, searchString: action.payload }
    }
    // case constants.SET_LISTING_LIMIT: {

    //   return { ...state, limit: action.payload }
    // }
    // case constants.SAVE_CLASS_DETAILS:
    //   return {
    //     ...state,
    //     classDetails: action.payload
    //   }
    case constants.SET_ACTIVE_CLASS_PROFILE:
      console.log(action.payload,"action payload for active class")
      return {
        ...state,
        activeClassProfile: action.payload
      }
    case constants.SET_CLASS_PROFILE_SIDEBAR_VISIBILITY:
      return {
        ...state,
        classProfileSidebarVisibility: action.payload
      }
    case constants.SET_ADD_CLASS_SIDEBAR_VISIBILITY:
      return {
        ...state,
        addClassSidebarVisibility: action.payload
      }
      // case constants.SET_CLASS_FILTER_SIDEBAR_VISIBILITY:
      // return {
      //   ...state,
      //   classFilterSidebarVisibility: action.payload
      // }
    case constants.SET_CLASS_CHECKED_ITEMS:
      return {
        ...state,
        checkedItems: action.payload
      }
    // case constants.SET_CLASS_FILTERS:
    //   return {
    //     ...state,
    //     classFilters: action.payload
    //   }
      case constants.SET_SINGLE_CLASS_VISIBILITY:
      return {
        ...state,
        viewSingleClassVisibility: action.payload
      }
    case constants.SET_SINGLE_CLASS_LIST:
      { 
        return{
          ...state,
          singleClassList:action.payload,
          className:action.payload.class_name,
          classTotalStudents :action.payload.total_class_student

        }
        
        // const class_name = action.payload.class_name
        // const total_students= 77
        // // oldListing.section.reduce((totalStudent,item)=>totalStudent+item.total_students)

        // const activePage = page
        
        // return {
        //   ...state,
        //   singleClassList:{oldListing},
        //   class_name,
        //   total_students
        // }
      }
      
    case constants.CLEAR_CLASS_LISTING:

      return {
        ...state,
        classListing: {},
        activePage: 1,
        searchClassListing: [],
      }
    default: {
      return state
    }
  }
}

import * as constants from '../../constants'

const initialState = {
  adminListing: [],
  addAdminSidebarVisibility: false,
  addAdminSuccessMessage : '',
  profile:{}
}

export const adminManagement = (state = initialState, action) => {
  switch (action.type) {
    case constants.SAVE_ADMIN_LISTING: {
      return { ...state, adminListing: action.payload.docs || [] }
    }

    case constants.SET_ADD_ADMIN_SIDEBAR_VISIBILITY:
      return {
        ...state,
        addAdminSidebarVisibility: action.payload
      }
    case constants.SET_ADD_ADMIN_SUCCESS_MESSAGE:
      return {
        ...state,
        addAdminSuccessMessage: action.payload
      }
    case constants.CLEAR_ADMIN_LISTING:
      return {
        ...state,
        adminListing: []
      }
     case constants.SET_ADMIN_PROFILE_PAGE:
       return {
         ...state,
         profile : action.payload.data
       }
       case constants.SET_LIVE_SESSIONS:
       return {
         ...state,
         liveSessions : action.payload.data
       }

       case "LOGOUT_WITH_JWT": {
        return { ...state, profile: {} }
      }
    default: {
      return state
    }
  }
}

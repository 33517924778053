import * as constants from "../../constants";

const initialState = {
  data: null,
  attachmentData: "",
  isLoading: false,
};

export const composeMail = (state = initialState, action) => {
  switch (action.type) {
    case constants.SENT_MAIL_SUCCESS: {
      return { ...state, data: action.payload, attachmentData: "" };
    }
    case constants.UPLOAD_ATTACHMENT_REQUEST: {
      return { ...state, isLoading: true };
    }
    case constants.UPLOAD_ATTACHMENT_SUCCESS: {
      return { ...state, attachmentData: action.payload, isLoading: false };
    }
    case constants.UPLOAD_ATTACHMENT_FAILED: {
      return { ...state, isLoading: false };
    }
    case constants.UPLOADED_ATTACHMENT_DELETE_SUCCESS
    : {
      return { ...state, attachmentData: action.payload };
    }
    default: {
      return state;
    }
  }
};

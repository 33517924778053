import * as constants from '../../constants/notification/notifications.constant'
const initialState = {
    newNotificationAvaliable: false,
    bellNotification: [],
    total_unread: 0
}
export const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.SET_NOTIFICATION_AVAILABLE_STATUS: {

            return { ...state, newNotificationAvaliable: action.payload }
        }
        case constants.SAVE_BELL_NOTIFICATION:
            return {
                ...state,
                bellNotification: action.payload.bell_notifications,
                total_unread: action.payload.total_unread_notifications
            }
        case constants.SET_SINGLE_NOTIFICATION_ID: {

            return { ...state, singleNotificationId: action.payload }
        }
        default: {
            return state
        }
    }
}

export const SENT_MAIL_SUCCESS = 'SENT_MAIL_SUCCESS';
export const UPLOAD_ATTACHMENT_REQUEST = 'UPLOAD_ATTACHMENT_REQUEST';
export const UPLOAD_ATTACHMENT_SUCCESS = 'UPLOAD_ATTACHMENT_SUCCESS';
export const UPLOAD_ATTACHMENT_FAILED = 'UPLOAD_ATTACHMENT_FAILED';
export const UPLOADED_ATTACHMENT_DELETE_REQUEST = 'UPLOADED_ATTACHMENT_DELETE_REQUEST';
export const UPLOADED_ATTACHMENT_DELETE_SUCCESS = 'UPLOADED_ATTACHMENT_DELETE_SUCCESS';
export const UPLOADED_ATTACHMENT_DELETE_FAILED = 'UPLOADED_ATTACHMENT_DELETE_FAILED';





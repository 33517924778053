import axios from 'axios';

export const loginInfoFetch = (payload) =>{
    const payloadlogin ={...payload
        // "device_type" :"ADMIN"
    }
    console.log(payloadlogin,"payloadlogin")

    let URL =`${process.env.REACT_APP_API_URL}/user/login`
    console.log(URL,"url")
     const headers ={
         "content-Type" : "application/json",
         "device_type"  : "ADMIN"
     }
    return axios({
        url: URL,
        method: "POST",
        headers: headers,
        data : JSON.stringify(payloadlogin)
    })
     .then(response =>{
         return response.data

     })
 }
 export const validateToken = async (payload) => {
    console.log("my paylad",payload);
    const payloadlogin ={...payload ,
        "device_type":"ADMIN"
        }
    let URL = `${process.env.REACT_APP_API_URL}/user/verify-token`
    const headers ={
        "content-type" : "application/json",
        "device_type"  : "ADMIN"
    }
    return axios({
        url: URL,
        method: "POST",
        headers: headers,
        data: JSON.stringify(payloadlogin)
    }).then(response=>{
        return response.data
    });

  }

  export const verifyOtpToResetPassword = (payload) =>{
    const payloadlogin ={...payload ,
        "device_type":"ADMIN"
        }

    let URL =  `${process.env.REACT_APP_API_URL}/user/verify-otp-to-reset-password`
    const headers ={
        "content-Type" : "application/json",
        "device_type"  : "ADMIN"
    }

    return axios({
        url: URL,
        method: "POST",
        headers: headers,
        data : JSON.stringify(payloadlogin)
    }).then(response=>{
        return response
    })
}

export const reSendOtpToResetPasswordOnMail = (payload) =>{
    let URL =  `${process.env.REACT_APP_API_URL}/user/resend-reset-password-otp`
    const payloadlogin ={...payload ,
        "device_type":"ADMIN"
        }
    const headers ={
        "content-Type" : "application/json",
        "device_type"  : "ADMIN"
    }
    return axios({
        url: URL,
        method: "POST",
        headers: headers,
        data : JSON.stringify(payloadlogin)
    }).then(response=>{
        return response
    })
}
export const resetPassword = (payload) =>{

    let URL = `${process.env.REACT_APP_API_URL}/user/reset-password`
    const payloadlogin ={...payload ,
        "device_type":"ADMIN"
        }
    const headers ={
        "content-Type" : "application/json",
        "device_type"  : "ADMIN"
    }
    return axios({
        url: URL,
        method: "POST",
        headers: headers,
        data : JSON.stringify(payloadlogin)
    })
    .then(response =>{
        return response.data
    })
}
export const forgotPassword = (payload) =>{

    let URL = `${process.env.REACT_APP_API_URL}/user/forget-password`
    console.log(payload,"forfrr")
    const payloadlogin ={...payload,
        "device_type":"ADMIN"
        }
    const headers ={
        "content-Type" : "application/json",
        "device_type"  : "ADMIN"
    }
    return axios({
        url: URL,
        method: "POST",
        headers: headers,
        data : JSON.stringify(payloadlogin)
    })
    .then(response =>{
        return response.data
    })
}

export const getOtpForLogin = (payload) =>{
    let URL = `${process.env.REACT_APP_API_URL}/user/get-otp-for-login?email=${payload}`
    const headers ={
        "content-Type" : "application/json",
        "device_type"  : "ADMIN"
    }
    return axios({
        url: URL,
        method: "GET",
        headers: headers,
    })
    .then(response =>{
        return response.data
    })
}

export const loginWithOtpService = (payload) =>{
    let URL = `${process.env.REACT_APP_API_URL}/user/login-with-otp`
    const headers ={
        "content-Type" : "application/json",
        "device_type"  : "ADMIN"
    }
    return axios({
        url: URL,
        method: "POST",
        headers: headers,
        data : JSON.stringify(payload)
    })
    .then(response =>{
        return response.data
    })
}


export const uploadFacesToS3 = (payload) => {
    let URL = `${process.env.REACT_APP_API_URL}/upload`;
  
    let formData = new FormData();
    formData.append("folderName", "faces");
    formData.append("file", payload);
  
    return axios.post(URL, formData).then((response) => {
      return response.data;
    });
  };

  export const registerStudentFaces = (payload) => {
    const token = localStorage.getItem("access_admin_token");
  
    let URL = `${process.env.REACT_APP_API_URL}/Attendance/register-faces`;
    const headers = {
      "content-Type": "application/json",
      token: token,
    };
    return axios({
      url: URL,
      method: "POST",
      headers: headers,
      data: JSON.stringify(payload),
    }).then((response) => {
      return response.data;
    });
  };

  export const verifyFaceAndMarkAttendance = (payload) => {
    const token = localStorage.getItem("access_admin_token");
  
    let URL = `${process.env.REACT_APP_API_URL}/Attendance/verify-and-mark-attendance`;
    const headers = {
      "content-Type": "application/json",
      token: token,
    };
    return axios({
      url: URL,
      method: "POST",
      headers: headers,
      data: JSON.stringify(payload),
    }).then((response) => {
      return response.data;
    });
  };

  export const markAttendanceWithStudentID = (payload) => {
    const token = localStorage.getItem("access_admin_token");
  
    let URL = `${process.env.REACT_APP_API_URL}/Attendance/mark-attendance-using-id`;
    const headers = {
      "content-Type": "application/json",
      token: token,
    };
    return axios({
      url: URL,
      method: "POST",
      headers: headers,
      data: JSON.stringify(payload),
    }).then((response) => {
      return response.data;
    });
  };


import * as constants from '../../constants'

const initialState = {
  studentListing: {},
  activePage: 1,
  searchStudentListing: [],
  searchString: '',
  limit: 10,
  total:0,
  classDetails: {},
  activeStudentProfile: {},
  studentProfileSidebarVisibility: false,
  addStudentSidebarVisibility: false,
  checkedItems: new Map(),
  studentFilterSidebarVisibility : false,
  studentFilters : {},
  filteredStudentListing : {},
  editedStudent : {},
  studentEditProfileSidebarVisibility :false,
  activeStudentProfileEdit :{},
  isStudentDataFetching : false
  
}

export const studentManagement = (state = initialState, action) => {
  switch (action.type) {
    case constants.SAVE_STUDENT_LISTING: {
      const oldListing = { ...state.studentListing }
      oldListing[action.payload.page] = action.payload
      const activePage = action.payload.page
      return { ...state, studentListing: oldListing, activePage }
    }
    case constants.SAVE_STUDENT_SEARCH_LISTING: {
      const oldListing = action.payload
      // oldListing[action.payload.page] = action.payload
      // const activePage = action.payload.page
      return { ...state, searchStudentListing: oldListing }
    }
    case constants.SAVE_STUDENT_SEARCH_STRING: {

      return { ...state, searchString: action.payload }
    }
    case constants.SET_LISTING_LIMIT: {

      return { ...state, limit: action.payload }
    }
    case constants.SAVE_CLASS_DETAILS:
      return {
        ...state,
        classDetails: action.payload
      }
    case constants.SET_ACTIVE_STUDENT_PROFILE:
      return {
        ...state,
        activeStudentProfile: action.payload
      }
    case constants.SET_STUDENT_PROFILE_SIDEBAR_VISIBILITY:
      return {
        ...state,
        studentProfileSidebarVisibility: action.payload
      }
    case constants.SET_ADD_STUDENT_SIDEBAR_VISIBILITY:
      return {
        ...state,
        addStudentSidebarVisibility: action.payload
      }
    case constants.SET_STUDENT_FILTER_SIDEBAR_VISIBILITY:
      return {
        ...state,
        studentFilterSidebarVisibility: action.payload
      }
    case constants.SET_CHECKED_ITEMS:
      return {
        ...state,
        checkedItems: action.payload
      }
    case constants.SET_STUDENT_FILTERS:
      return {
        ...state,
        studentFilters: action.payload
      }
      case constants.SET_EDITED_STUDENT_PROFILE:
        return {
          ...state,
          editedStudent: action.payload
        }
      case constants.SET_ACTIVE_STUDENT_EDIT:
      return {
        ...state,
        activeStudentProfileEdit: action.payload
      }
    case constants.SET_STUDENT_EDIT_SIDEBAR_VISIBILITY:
      return {
        ...state,
        studentEditProfileSidebarVisibility: action.payload
      }
    case constants.CLEAR_STUDENT_LISTING:

      return {
        ...state,
        studentListing: {},
        filteredStudentListing: {},
        activePage: 1,
        searchStudentListing: [],
        checkedItems: new Map(),
      }
      case constants.SAVE_FILTERED_STUDENT_LISTING_REQUEST:{
        return {
          ...state,
          isStudentDataFetching: true
        }
      }
      case constants.SAVE_FILTERED_STUDENT_LISTING_SUCCESS: {
        const oldListing = { ...state.filteredStudentListing }
        oldListing[action.payload.page] = action.payload
        const activePage = action.payload.page || 1
        const total = action.payload.total
        return { ...state, filteredStudentListing: oldListing, activePage,total:total, isStudentDataFetching: false}
      }
      case constants.SAVE_FILTERED_STUDENT_LISTING_FAILURE:{
        return {
          ...state,
          isStudentDataFetching: false
        }
      }
    default: {
      return state
    }
  }
}

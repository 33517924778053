
export const SAVE_SEARCH_LISTING = 'SAVE_SEARCH_LISTING'
export const SAVE_SEARCH_STRING = 'SAVE_SEARCH_STRING'
export const SET_LISTING_LIMIT = 'SET_LISTING_LIMIT'
export const SAVE_CLASS_DETAILS = 'SAVE_CLASS_DETAILS'
export const SET_PROFILE_SIDEBAR_VISIBILITY = 'SET_PROFILE_SIDEBAR_VISIBILITY'

export const SET_ACTIVE_PROFILE = 'SET_ACTIVE_PROFILE'
export const CLEAR_LISTING = 'CLEAR_LISTING'
export const SET_CHECKED_ITEMS = 'SET_CHECKED_ITEMS'
export const SET_FILTER_SIDEBAR_VISIBILITY = 'SET_FILTER_SIDEBAR_VISIBILITY'
export const SET_FILTERS = 'SET_FILTERS'
export const SAVE_FILTERED_LISTING = 'SAVE_FILTERED_LISTING'
export const SWITCH_ATTENDANCE_TABLE='SWITCH_ATTENDANCE_TABLE'
export const SAVE_ATTENDANCE_CHART='SAVE_ATTENDANCE_CHART'



export  const LOGIN_WITH_JWT = "LOGIN_WITH_JWT"
export const LOGIN_JWT_FAILED = "LOGIN_JWT_FAILED"
export const UPDATE_TOKEN="UPDATE_TOKEN"
export  const USER_FORGOT_PASSWORD_REQUEST = "USER_FORGOT_PASSWORD_REQUEST"
export const USER_FORGOT_PASSWORD_SUCCESS = "USER_FORGOT_PASSWORD_SUCCESS"
export const USER_FORGOT_PASSWORD_FAIL="USER_FORGOT_PASSWORD_FAIL"
export  const USER_RESET_PASSWORD_REQUEST = "USER_RESET_PASSWORD_REQUEST"
export const USER_RESET_PASSWORD_SUCCESS = "USER_RESET_PASSWORD_SUCCESS"
export const USER_RESET_PASSWORD_FAIL="USER_RESET_PASSWORD_FAIL"
export  const GET_OTP_FOR_LOGIN_REQUEST = "GET_OTP_FOR_LOGIN_REQUEST"
export const GET_OTP_FOR_LOGIN_SUCCESS = "GET_OTP_FOR_LOGIN_SUCCESS"
export const GET_OTP_FOR_LOGIN_FAILED="GET_OTP_FOR_LOGIN_FAILED"
export  const LOGIN_WITH_OTP_REQUEST = "LOGIN_WITH_OTP_REQUEST"
export const LOGIN_WITH_OTP_SUCCESS = "LOGIN_WITH_OTP_SUCCESS"
export const LOGIN_WITH_OTP_FAILED="LOGIN_WITH_OTP_FAILED"

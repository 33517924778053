import * as actions from '../../constants'

const initialState = {
    teacherListing: {},
    activePage : 1,
    searchTeacherListing : [],
    searchString : '',
    limit : 10,
    total:0,
    classDetails: {},
    activeTeacherProfile: {},
    activeTeacherProfileEdit :{},
    teacherProfileSidebarVisibility: false,
    teacherEditProfileSidebarVisibility :false,
    addTeacherSidebarVisibility: false,
    checkedItems: new Map(),
    teacherFilterSidebarVisibility : false,
    teacherFilters : {},
    filteredTeacherListing : {},
    editedTeacher : {},
    isTeacherDataFetching : false
}
const teacherReducer =(state=initialState ,action)=>{

    switch(action.type){
     
        case actions.SET_All_TEACHERS_LIST: {
          const oldListing = {...state.teacherListing}
          oldListing[action.payload.page] = action.payload
          const activePage = action.payload.page
          return { ...state, teacherListing: oldListing, activePage}
        }
        case actions.SAVE_TEACHER_SEARCH_LISTING: {
          const oldListing  = action.payload
          // oldListing[action.payload.page] = action.payload
          // const activePage = action.payload.page
          return { ...state, searchTeacherListing: oldListing}
        }
      case actions.SAVE_TEACHER_SEARCH_STRING: {

        return { ...state, searchString: action.payload }
      }
      case actions.SET_TEACHER_LISTING_LIMIT: {

        return { ...state, limit: action.payload }
      }
      case actions.SAVE_CLASS_DETAILS:
      return {
        ...state,
        classDetails: action.payload
      }
    case actions.SET_ACTIVE_TEACHER_PROFILE:
      return {
        ...state,
        activeTeacherProfile: action.payload
      }
      
      case actions.SET_EDITED_TEACHER_PROFILE:
        return {
          ...state,
          editedTeacher: action.payload
        }
      case actions.SET_ACTIVE_TEACHER_EDIT:
      return {
        ...state,
        activeTeacherProfileEdit: action.payload
      }
    case actions.SET_TEACHER_EDIT_SIDEBAR_VISIBILITY:
      return {
        ...state,
        teacherEditProfileSidebarVisibility: action.payload
      }
      case actions.SET_TEACHER_PROFILE_SIDEBAR_VISIBILITY:
        return {
          ...state,
          teacherProfileSidebarVisibility: action.payload
        }
    case actions.SET_ADD_TEACHER_SIDEBAR_VISIBILITY:
      return {
        ...state,
        addTeacherSidebarVisibility: action.payload
      }
      case actions.SET_TEACHER_FILTER_SIDEBAR_VISIBILITY:
        return {
          ...state,
          teacherFilterSidebarVisibility: action.payload
        }
      case actions.SET_TEACHER_CHECKED_ITEMS:
        return {
          ...state,
          checkedItems: action.payload
        }
      case actions.SET_TEACHER_FILTERS:
        return {
          ...state,
          teacherFilters: action.payload
        }
    case actions.CLEAR_TEACHER_LISTING:

      return {
        ...state,
        teacherListing: {},
        filteredTeacherListing: {},
        activePage: 1,
        searchTeacherListing: [],
        checkedItems: new Map(),
      }

      case actions.SAVE_FILTERED_TEACHER_LISTING_REQUEST:{
        return {
          ...state,
          isTeacherDataFetching: true
        }
      }

      case actions.SAVE_FILTERED_TEACHER_LISTING_SUCCESS: {
        const oldListing = { ...state.filteredTeacherListing }
        oldListing[action.payload.page] = action.payload
        const activePage = action.payload.page || 1
        const total=action.payload.total
        return { ...state, filteredTeacherListing: oldListing, activePage,total:total, isTeacherDataFetching: false }
      }
      case actions.SAVE_FILTERED_TEACHER_LISTING_FAILURE:{
        return {
          ...state,
          isTeacherDataFetching: false
        }
      }

        default: {
          return state
        }
      }
}
export default teacherReducer
import * as constants from '../../constants'

const initialState = {
 dashboardTopStats :{},
}

export const dashboardManagement = (state = initialState, action) => {
  switch (action.type) {
    case constants.SAVE_DASHBOARD_TOP_STATS: {
     
      return { ...state, dashboardTopStats: action.payload }
    }
       default: {
      return state
    }
  }
}
